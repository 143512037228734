/* eslint-disable import/no-default-export */
import { IonGrid, IonPage } from '@ionic/react'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'
import { Header } from '../../components/Header'
import { ProjectMobileControls as MobileControls } from '../../components/ProjectMobileControls'
import { UserProfileView } from '../../components/UserProfile'
import { useDisplaySize } from '../../helpers/useDisplaySize'
import { useUserProfile } from './ProfilePage.hooks'
import { ProfileContentProps, UserProfileQuery } from './ProfilePage.types'
import { BackendError } from '../../components/BackendError'
import WaitingSpinner from '../../components/ui/WaitingSpinner/WaitingSpinner'

const StyledIonGrid = styled(IonGrid)`
  && {
    width: 100%;
    margin: auto;
    max-width: var(--narrow-page-max-size);
    padding-left: 50px;
    padding-right: 50px;

    @media (max-width: 768px) {
      padding-left: 15px;
      padding-right: 15px;
      font-size: 12px;
    }
  }
`

const StyledPageContainer = styled.div`
  overflow: hidden;
  height: 100%;
`

const StyledContent = styled.div`
  overflow: scroll;
  height: 100%;
  padding-top: 60px;
  max-width: var(--max-width);
`

const ProfileContent: React.VFC<ProfileContentProps> = ({ userProfile }) => {
  const { isMobile } = useDisplaySize()
  return (
    <StyledContent>
      <StyledIonGrid>
        <UserProfileView user={userProfile} />
      </StyledIonGrid>
      {isMobile && <MobileControls />}
    </StyledContent>
  )
}

const ProfilePage = ({ match }: RouteComponentProps<UserProfileQuery>) => {
  const { user, loading, error } = useUserProfile(parseInt(match.params.id, 10))

  let content = <ProfileContent userProfile={user} />

  if (loading) {
    content = <WaitingSpinner />
  }

  if (error) {
    let message = 'Something went wrong. Please try again later.'
    const error404 = error.graphQLErrors.find(
      (err) => err.extensions.code === '404',
    )

    if (error404) {
      message =
        'This profile does not exist or You don’t have permission to see this profile.'
    }

    content = <BackendError message={message} routeReturnTo="/" />
  }

  return (
    <IonPage>
      <StyledPageContainer>
        <Header />
        {content}
      </StyledPageContainer>
    </IonPage>
  )
}

export default ProfilePage
