/* eslint-disable import/no-default-export */
import { IonIcon, IonPage } from '@ionic/react'
import { RouteComponentProps } from 'react-router-dom'
import { logOutOutline } from 'ionicons/icons'
import { BackendError } from '../../components/BackendError'
import { SharedAssetQuery } from './SharedAssetPage.types'
import { ProjectDigitalTwinFullScreen } from '../../components/ProjectDigitalTwinAssetFullScreen'
import { useSharedAssetQuery } from './SharedAssetPage.hooks'
import { ThumbnailedAsset } from '../ProjectPage/ProjectPage.types'
import { useIntercomDisabled } from '../../helpers/useIntercomDisabled'
import { useLogoutMutation } from '../../components/ui/UserInfo/UserInfo.hooks'
import { useAuthContext } from '../../contexts/auth'
import styles from './SharedAssetPage.module.css'
import { FlexBox } from '../../components/ui/FlexBox'
import WaitingSpinner from '../../components/ui/WaitingSpinner/WaitingSpinner'

const SharedAssetPage = ({ match }: RouteComponentProps<SharedAssetQuery>) => {
  const { asset, loading, error } = useSharedAssetQuery({
    uuid: match.params.uuid,
  })

  const { logout } = useLogoutMutation()
  const { setIsLoggedIn } = useAuthContext()

  const onLogoutClick = () => {
    logout()
    setIsLoggedIn(false)
  }

  useIntercomDisabled()

  let content = (
    <ProjectDigitalTwinFullScreen
      digitalTwinAsset={asset as ThumbnailedAsset}
    />
  )
  if (loading) {
    content = <WaitingSpinner />
  }
  if (error) {
    let message = 'Something went wrong. Please try again later.'
    const error404 = error.graphQLErrors.find(
      (err) => err.extensions.code === '404',
    )

    if (error404) {
      message =
        'You have insufficient permissions to view this page. If you believe this is an error, please contact support or your local administrator.'
    }

    content = <BackendError message={message} routeReturnTo="/" />
  }

  return (
    <IonPage data-testid="ProjectSharedPage">
      <div className={styles.container}>
        <FlexBox fullWidth direction="row">
          <div className={styles.title}>{asset?.name || 'Digital Twin'}</div>
          <FlexBox className={styles.topMenu} alignItems="flex-end" gap="10">
            <div className={styles.menuItem} onClick={onLogoutClick}>
              Logout
              <IonIcon slot="end" icon={logOutOutline} />
            </div>
          </FlexBox>
        </FlexBox>
        {content}
      </div>
    </IonPage>
  )
}

export default SharedAssetPage
